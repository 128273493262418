import request from "@/router/axios";
import store from "@/store";

export const changePasswordApi = data => {
    return request({
        url: "/api/admin/account/changePassword",
        method: "post",
        data,
        headers: { "auth-code": store.state.sendCode.authCode }
    });
};

export const sendVerityCodeApi = data => {
    return request({
        url: "/api/admin/account/sendVerityCodeToEmail",
        method: "post",
        data
    });
};

export const changeEmailApi = data => {
    return request({
        url: "/api/admin/account/changeEmail",
        method: "post",
        data,
        headers: { "auth-code": store.state.sendCode.authCode }
    });
};
