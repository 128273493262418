import request from "@/router/axios";

export const sendCodeApi = data => {
    return request({
        url: "/api/admin/sendCode/send",
        method: "post",
        data
    });
};

export const verityCodeApi = data => {
    return request({
        url: "/api/admin/sendCode/verityCode",
        method: "post",
        data
    });
};
