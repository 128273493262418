<template>
    <el-dialog
        class="send-code"
        :title="title"
        :visible.sync="isShow"
        width="450px"
        :close-on-click-modal="false"
        @closed="closedHandler"
        :key="key"
    >
        <div class="tip">
            <span
                >您的账号可能存在安全风险，为了确保您本人操作，请先进行安全验证。</span
            >
        </div>
        <el-form
            ref="send-code-form"
            class="send-code-form"
            :rules="rules"
            :model="form"
            label-position="top"
        >
            <el-form-item label="验证方式" prop="type">
                <el-row>
                    <el-col :span="24">
                        <el-select
                            v-model="form.type"
                            placeholder="请选择验证方式"
                        >
                            <el-option
                                v-for="item in showTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-if="sendType == 'code'">
                <el-row type="flex" justify="space-between" :gutter="20">
                    <el-col :span="15">
                        <el-input
                            v-model="form.code"
                            maxlength="6"
                            placeholder="请输入验证码"
                        ></el-input>
                    </el-col>
                    <el-col :span="9">
                        <el-button
                            @click="isShowVcode = true"
                            :disabled="!isAllowSend"
                        >
                            <span>{{
                                isAllowSend
                                    ? "发送验证码"
                                    : `${sendCode.verityCodeCountDown}秒后重新发送`
                            }}</span>
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" v-show="showConfirmButton">
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
        <Vcode
            class="vcode-dialog"
            :show="isShowVcode"
            @success="sendCodeHandler"
            @close="isShowVcode = false"
        />
    </el-dialog>
</template>
<script>
import Vcode from "vue-puzzle-vcode";
import { mapGetters } from "vuex";
import { sendCodeApi, verityCodeApi } from "@/api/admin/sendCode";
export default {
    name: "SendCode",
    components: {
        Vcode
    },
    props: {
        title: {
            type: String,
            default: () => "安全验证"
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters(["userInfo", "sendCode"]),
        isShow: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        },
        rules() {
            let res = {
                type: [
                    {
                        required: true,
                        message: "请选择验证方式",
                        trigger: "blur"
                    }
                ]
            };
            switch (this.sendType) {
                case "code":
                    Object.assign(res, {
                        code: [
                            {
                                required: true,
                                message: "请输入验证码",
                                trigger: "blur"
                            },
                            {
                                min: 6,
                                message: "长度为 6 个字符",
                                trigger: "blur"
                            }
                        ]
                    });
                    break;
                default:
                    break;
            }
            return res;
        },
        showTypeOptions() {
            let res = [];
            for (let item of this.typeOptions) {
                if (this.userInfo[item.exist]) {
                    let temp = this.userInfo[item.exist];
                    let label = item.name;
                    if (item.reg) {
                        try {
                            temp = temp.replace(item.reg, "$1****$2");
                            label = `（${temp}）${label}`;
                        } catch (err) {
                            console.log(err);
                        }
                    }
                    res.push({
                        label,
                        value: item.type
                    });
                }
            }
            return res;
        },
        sendType() {
            let type = this.form.type;
            switch (type) {
                case 0:
                case 1:
                    return "code";
                default:
                    return "unknow";
            }
        },
        showConfirmButton() {
            switch (this.sendType) {
                case "code":
                    return true;
                default:
                    return false;
            }
        },
        isAllowSend() {
            return this.sendCode.verityCodeCountDown < 0;
        }
    },
    data() {
        return {
            key: 0,
            form: {
                type: undefined,
                code: ""
            },
            typeOptions: [
                {
                    type: 0,
                    name: "密保邮箱",
                    exist: "email",
                    reg: /^(.{2}).+(.{1}@.+)$/g
                },
                {
                    type: 1,
                    name: "手机",
                    exist: "phone",
                    reg: /^(.{3}).+(.{2})$/g
                }
            ],
            isShowVcode: false
        };
    },
    methods: {
        async callSendCode() {
            return new Promise((resolve, reject) => {
                sendCodeApi({
                    type: this.form.type
                })
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$notify({
                                title: "成功",
                                message: "发送成功",
                                type: "success"
                            });
                            resolve();
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "发送失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async callVerityCodeApi() {
            return new Promise((resolve, reject) => {
                verityCodeApi(this.form)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "验证失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        onSubmit() {
            this.$refs["send-code-form"].validate(valid => {
                if (valid) {
                    this.callVerityCodeApi().then(authCode => {
                        this.$store.dispatch("saveAuthCode", authCode);
                        this.isShow = false;
                        this.$emit("handler");
                    });
                } else {
                    return false;
                }
            });
        },
        closedHandler() {
            this.$refs["send-code-form"].resetFields();
            this.key++;
        },
        sendCodeHandler() {
            this.isShowVcode = false;
            this.callSendCode().then(() => {
                this.$store.dispatch("doCountDwonVerityCode", 60);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.send-code {
    .tip {
        margin-bottom: 30px;
    }
    .send-code-form {
        /deep/ .el-select,
        /deep/ .el-input,
        /deep/ .el-button {
            width: 100%;
        }
    }
    .dialog-footer {
        button {
            width: 100%;
        }
    }
}
.vcode-dialog {
    z-index: 3001;
}
</style>
