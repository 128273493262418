<template>
    <div class="change-container" v-show="isShow">
        <h2 class="header-ctrl">
            <el-button
                type="text"
                icon="el-icon-arrow-left"
                @click="isShow = false"
                >返回</el-button
            >
        </h2>
        <component
            ref="changeComponent"
            :is="type"
            @back="isShow = false"
        ></component>
    </div>
</template>

<script>
import Password from "./changePassword.vue";
import Email from "./changeEmail.vue";
import Phone from "./changePhone.vue";
export default {
    name: "ChangeContainer",
    components: {
        Password,
        Email,
        Phone
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    watch: {
        isShow() {
            this.$refs["changeComponent"] &&
                this.$refs["changeComponent"].resetForm &&
                this.$refs["changeComponent"].resetForm();
        }
    },
    computed: {
        isShow: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.change-container {
    .header-ctrl {
        margin: 0 0 20px 0;
        button,
        a {
            font-size: 16px;
            color: #909399;
        }
    }
}
</style>
