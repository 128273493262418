<template>
    <div class="change-email">
        <el-steps
            class="change-email-steps"
            :active="active"
            finish-status="success"
            align-center
        >
            <el-step title="输入新邮箱"></el-step>
            <el-step title="验证邮箱"></el-step>
            <el-step title="绑定成功"></el-step>
        </el-steps>
        <el-form
            v-show="active == 0"
            class="change-email-form"
            ref="form"
            :model="form"
            label-width="100px"
            :rules="rules"
            status-icon
        >
            <el-form-item label="原邮箱" prop="oldEmail">
                <el-input
                    v-model="form.oldEmail"
                    auto-complete="off"
                    :disabled="true"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="新邮箱" prop="newEmail">
                <el-input v-model="form.newEmail" auto-complete="off">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="toVerityStep"
                    >下一步</el-button
                >
            </el-form-item>
        </el-form>

        <el-form
            v-show="active == 1"
            class="change-email-form"
            ref="verity-code-form"
            :model="verityCodeForm"
            label-width="100px"
            :rules="verityCodeRules"
            status-icon
        >
            <el-form-item label="验证码" prop="code">
                <el-row type="flex" justify="space-between" :gutter="20">
                    <el-col :span="15">
                        <el-input
                            v-model="verityCodeForm.code"
                            maxlength="6"
                            placeholder="请输入验证码"
                        ></el-input>
                    </el-col>
                    <el-col :span="9">
                        <el-button
                            @click="sendVerityCode"
                            :disabled="!isAllowSend"
                        >
                            <span>{{
                                isAllowSend
                                    ? "发送验证码"
                                    : `${verityCodeCountDown}秒后重新发送`
                            }}</span>
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button @click="active--">上一步</el-button>
                <el-button type="primary" @click="doSaveHandler"
                    >保存</el-button
                >
            </el-form-item>
        </el-form>

        <el-result
            v-show="active == 3"
            icon="success"
            title="设置新邮箱成功"
            subTitle="请在24小时内，登入新邮箱并确认！"
        >
            <template slot="extra">
                <el-button type="primary" size="medium" @click="backHandler"
                    >返回</el-button
                >
            </template>
        </el-result>
        <SendCode
            :visible.sync="sendCodeDialog.visible"
            :title="sendCodeDialog.title"
            @handler="doSave"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sendVerityCodeApi, changeEmailApi } from "@/api/admin/account";
import SendCode from "@/components/send-code";

export default {
    name: "ChangeEmail",
    components: {
        SendCode
    },
    computed: {
        ...mapGetters(["userInfo", "sendCode"]),
        isAllowSend() {
            return this.verityCodeCountDown < 0;
        },
        sendVerityParams() {
            let params = Object.assign({}, this.form);
            delete params.oldEmail;
            return params;
        },
        changeEmailParams() {
            let params = Object.assign({}, this.verityCodeForm);
            return params;
        }
    },
    data() {
        return {
            active: 0,
            form: {
                oldEmail: "",
                newEmail: ""
            },
            verityCodeForm: {
                code: ""
            },
            verityCodeCountDown: -1,
            interval: undefined,
            rules: {
                newEmail: [
                    {
                        required: true,
                        message: "请输入新邮箱地址",
                        trigger: "blur"
                    },
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur", "change"]
                    }
                ]
            },
            verityCodeRules: {
                code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    },
                    {
                        min: 6,
                        message: "长度为 6 个字符",
                        trigger: "blur"
                    }
                ]
            },
            sendCodeDialog: {
                visible: false,
                title: "更改绑定邮箱"
            }
        };
    },
    methods: {
        async callSendVerityCodeApi() {
            return new Promise((resolve, reject) => {
                sendVerityCodeApi(this.sendVerityParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve();
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "发送失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async callChangeEmailApi() {
            return new Promise((resolve, reject) => {
                changeEmailApi(this.changeEmailParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$notify({
                                title: "成功",
                                message: "保存成功",
                                type: "success"
                            });
                            resolve();
                        } else if (res.data.code == 401) {
                            this.sendCodeDialog.visible = true;
                            reject();
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "保存失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        toVerityStep() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.active = 1;
                } else {
                    return false;
                }
            });
        },
        sendVerityCode() {
            if (!this.interval) {
                this.callSendVerityCodeApi().then(() => {
                    this.resetSendVerityCodeButton();
                });
            }
        },
        resetSendVerityCodeButton() {
            this.verityCodeCountDown = 60;
            this.interval = setInterval(() => {
                if (this.verityCodeCountDown < 0) {
                    clearInterval(this.interval);
                    this.interval = undefined;
                    return;
                } else {
                    this.verityCodeCountDown--;
                }
            }, 1000);
        },
        doSaveHandler() {
            this.$refs["verity-code-form"].validate(valid => {
                if (valid) {
                    if (this.sendCode.authCode) {
                        this.doSave();
                    } else {
                        this.sendCodeDialog.visible = true;
                    }
                } else {
                    return false;
                }
            });
        },
        doSave() {
            this.callChangeEmailApi().then(() => {
                this.active = 3;
            });
        },
        backHandler() {
            this.resetForm();
            this.$emit("back");
        },
        resetForm() {
            this.$refs["form"].resetFields();
            this.$refs["verity-code-form"].resetFields();

            // 给旧邮箱赋值
            this.$set(
                this.form,
                "oldEmail",
                this.userInfo["email"].replace(
                    /^(.{2}).+(.{1}@.+)$/g,
                    "$1****$2"
                )
            );

            this.$set(this, "active", 0);
        }
    },
    mounted() {
        this.resetForm();
    }
};
</script>

<style lang="scss" scoped>
.change-email {
    user-select: none;
    width: 460px;
    margin: 0 auto;
    .change-email-steps {
        margin-bottom: 50px;
    }
}
</style>
