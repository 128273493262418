<template>
    <div class="change-phone">
        change-phone
    </div>
</template>

<script>
    export default {
        name: 'ChangePhone'
    }
</script>

<style lang="scss" scoped>
</style>
