<template>
    <div class="change-password">
        <el-form
            v-show="!changeResult.show"
            class="change-password-form"
            ref="form"
            :model="form"
            label-width="100px"
            :rules="rules"
            status-icon
        >
            <el-form-item label="新密码" prop="newPassword">
                <el-input
                    :type="showPassword ? '' : 'password'"
                    v-model="form.newPassword"
                    auto-complete="off"
                >
                    <i
                        class="el-input__icon"
                        :class="
                            showPassword
                                ? 'iconfont icon-cp-not-visable'
                                : 'iconfont icon-cp-visable'
                        "
                        slot="suffix"
                        @click="showPasswordHandler"
                    ></i>
                </el-input>
            </el-form-item>
            <el-form-item
                label="确认新密码"
                prop="confirmPassword"
                auto-complete="off"
            >
                <el-input
                    :type="showPassword ? '' : 'password'"
                    v-model="form.confirmPassword"
                    auto-complete="off"
                >
                    <i
                        class="el-input__icon"
                        :class="
                            showPassword
                                ? 'iconfont icon-cp-not-visable'
                                : 'iconfont icon-cp-visable'
                        "
                        slot="suffix"
                        @click="showPasswordHandler"
                    ></i>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSaveHandler"
                    >保存</el-button
                >
            </el-form-item>
        </el-form>
        <el-result
            v-show="changeResult.show"
            icon="success"
            title="设置新密码成功"
            subTitle=""
        >
            <template slot="extra">
                <el-button type="primary" size="medium" @click="backHandler"
                    >返回</el-button
                >
            </template>
        </el-result>
        <SendCode
            :visible.sync="sendCodeDialog.visible"
            :title="sendCodeDialog.title"
            @handler="doSave"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changePasswordApi } from "@/api/admin/account";
import SendCode from "@/components/send-code";
export default {
    name: "ChangePassword",
    components: {
        SendCode
    },
    computed: {
        ...mapGetters(["sendCode"]),
        reqParams() {
            let params = Object.assign({}, this.form);
            delete params.confirmPassword;
            return params;
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.form.newPassword !== "") {
                    this.$refs.form.validateField("confirmPassword");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.newPassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            showPassword: false,
            form: {
                newPassword: "",
                confirmPassword: ""
            },
            rules: {
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur"
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur"
                    },
                    { validator: validatePass, trigger: "blur" }
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "请再次输入密码",
                        trigger: "blur"
                    },
                    { validator: validatePass2, trigger: "blur" }
                ]
            },
            sendCodeDialog: {
                visible: false,
                title: "更改密码"
            },
            changeResult: {
                show: false
            }
        };
    },
    methods: {
        async callChangePasswordApi() {
            return new Promise((resolve, reject) => {
                changePasswordApi(this.reqParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve();
                        } else if (res.data.code == 401) {
                            this.sendCodeDialog.visible = true;
                            reject();
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "保存失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        showPasswordHandler() {
            this.showPassword = !this.showPassword;
        },
        onSaveHandler() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    if (this.sendCode.authCode) {
                        this.doSave();
                    } else {
                        this.sendCodeDialog.visible = true;
                    }
                } else {
                    return false;
                }
            });
        },
        doSave() {
            this.callChangePasswordApi().then(() => {
                this.changeResult.show = true;
            });
        },
        backHandler() {
            this.resetForm();
            this.$emit("back");
        },
        resetForm() {
            this.$refs["form"].resetFields();
        }
    }
};
</script>

<style lang="scss" scoped>
.change-password {
    user-select: none;
    width: 460px;
    margin: 0 auto;
}
</style>
